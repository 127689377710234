<template>
  <div class="edit-shift-calendar">
    <PageTitle title="編輯排班班表" hideBtn />

    <el-form label-position="top" :show-message="false">
      <FiltersContainer style="margin-bottom: 0">
        <el-form-item label="選擇服務車次">
          <ServicesUnitSelect data-cy="unit-select" :model.sync="unit" @change="changeServiceUnit(unit)" />
        </el-form-item>

        <el-form-item label="選擇排班班別">
          <el-select
            v-model="shiftClass"
            placeholder="請選擇"
            value-key="id"
            no-data-text="無數據"
            data-cy="shift-class-select"
          >
            <el-option
              v-for="item in shopShiftsClassList"
              :key="item.name"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </FiltersContainer>
    </el-form>

    <div v-loading="loading" class="flex-1" style="margin-top: 22px">
      <FullCalendar v-if="show && editCalendar.initialDate" ref="calendar" class="h-full" :options="editCalendar" />
      <div style="height: 200px" />
    </div>

    <div class="page-footer">
      <el-button
        style="width: 140px;"
        plain
        @click="cancelEdit"
      >
        取消
      </el-button>
      <el-button data-cy="save-schedule-btn" style="width: 140px;" type="primary" @click="saveEditSchedule">儲存</el-button>
    </div>
  </div>
</template>
<script>
import { uniqBy, findIndex } from 'lodash'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { FindServiceUnit } from '@/api/serviceUnit'

import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import shiftCalendar from '@/mixin/shiftCalendar'

const loadDebounceKeyList = {}
function localDebounce (key, next) {
  const stl = 500
  if (loadDebounceKeyList[key]) {
    if (loadDebounceKeyList[key] + stl > +new Date()) {
      return () => {}
    }
  }
  loadDebounceKeyList[key] = +new Date()
  return next()
}

export default {
  name: 'ShifCalendar',
  components: { ServicesUnitSelect, FullCalendar },
  mixins: [shiftCalendar],
  data () {
    return {
      show: true,
      unit: null,
      shiftClass: null,
      editNewSchedule: [],
      editUnitSchedule: {},
      unitSchedule: {},
      allSchedule: [],
      // 當前月曆顯示的日期區間

      editCalendar: {
        initialDate: null,
        contentHeight: 'auto',
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        buttonText: {
          today: '今日',
          month: '月檢視',
          week: '週檢視',
          day: '天檢視',
          list: '列表',
        },
        initialView: 'dayGridMonth',
        // 處理月曆 Toolbar 按鈕操控事件
        customButtons: {
          prev: {
            click: () => {
              this.$refs.calendar.getApi().prev()
              this.changeCalendar()
            },
          },
          next: {
            click: () => {
              this.$refs.calendar.getApi().next()
              this.changeCalendar()
            },
          },
          today: {
            text: '今日',
            click: () => {
              this.$refs.calendar.getApi().today()
              this.changeCalendar()
            },
          },
        },
        weekends: true,
        // longPressDelay: 100,
        selectLongPressDelay: 0,

        selectable: true,
        displayEventTime: true,
        displayEventEnd: true,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
        locale: 'en-gb',
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false,
        },
        dateClick: this.editDateClick,
        eventClick: this.editEventClick,
        events: [],
        dayMaxEventRows: true, // for all non-TimeGrid views
        views: {
          timeGrid: {
            // dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
          },
          dayGrid: {
            dayMaxEventRows: 10, // adjust to 6 only for timeGridWeek/timeGridDay
          },
        },
      },
    }
  },

  computed: {
    ...mapGetters(['shop']),
    sidebar () { return this.$store.state.app.sidebar },
    allMergeSchedule () {
      const schedule = []
      for (const unitId in this.editUnitSchedule) {
        const baseSchedule = JSON.parse(JSON.stringify(this.unitSchedule[unitId] || []))
        const editCahceSchedule = JSON.parse(JSON.stringify(this.editUnitSchedule[unitId] || []))

        editCahceSchedule.forEach((item) => {
          const existIndex = findIndex(baseSchedule, i => i.day === item.day)
          const shiftId = (item.schedule?.appointmentScheduleDayId || item.appointmentScheduleDayId)
          let shift = {}
          // const shift = find(this.shopShiftsClassList, { id: shiftId })
          this.shopShiftsClassList.forEach((i) => {
            if (i.id === shiftId) {
              shift = i
            }
          })

          if (existIndex !== -1) {
            if (item.mode === 'add') {
              this.$set(baseSchedule[existIndex], 'AppointmentScheduleDayId', item.schedule.appointmentScheduleDayId)
              this.$set(baseSchedule[existIndex], 'AppointmentScheduleDay', shift)
              // baseSchedule[existIndex].AppointmentScheduleDayId = item.schedule.appointmentScheduleDayId
              // baseSchedule[existIndex].AppointmentScheduleDay = shift
            }
            if (item.mode === 'remove') {
              baseSchedule[existIndex].AppointmentScheduleDayId = null
            }
          }
          if (existIndex === -1) {
            if (item.mode === 'add') {
              baseSchedule.push({
                AppointmentScheduleDay: shift,
                AppointmentScheduleDayId: item.schedule.appointmentScheduleDayId,
                AppointmentUnit: { ...this.unit },
                AppointmentUnitId: unitId,
                // ShopId: this.shop.id,
                day: item.day,
              })
            }
          }
        })
        schedule.push(...baseSchedule)
      }
      return schedule
    },
    mergeEditSchedule () {
      const unitId = this.unit?.id
      if (!unitId) return []
      const baseSchedule = JSON.parse(JSON.stringify(this.unitSchedule[unitId] || []))
      const editCahceSchedule = JSON.parse(JSON.stringify(this.editUnitSchedule[unitId] || []))

      editCahceSchedule.forEach((item) => {
        const existIndex = findIndex(baseSchedule, i => i.day === item.day)
        const shiftId = (item.schedule?.appointmentScheduleDayId || item.appointmentScheduleDayId)
        let shift = {}
        // const shift = find(this.shopShiftsClassList, { id: shiftId })
        this.shopShiftsClassList.forEach((i) => {
          if (i.id === shiftId) {
            shift = i
          }
        })

        if (existIndex !== -1) {
          if (item.mode === 'add') {
            this.$set(baseSchedule[existIndex], 'AppointmentScheduleDayId', item.schedule.appointmentScheduleDayId)
            this.$set(baseSchedule[existIndex], 'AppointmentScheduleDay', shift)
            // baseSchedule[existIndex].AppointmentScheduleDayId = item.schedule.appointmentScheduleDayId
            // baseSchedule[existIndex].AppointmentScheduleDay = shift
          }
          if (item.mode === 'remove') {
            baseSchedule[existIndex].AppointmentScheduleDayId = null
          }
        }
        if (existIndex === -1) {
          if (item.mode === 'add') {
            baseSchedule.push({
              AppointmentScheduleDay: shift,
              AppointmentScheduleDayId: item.schedule.appointmentScheduleDayId,
              AppointmentUnit: { ...this.unit },
              AppointmentUnitId: unitId,
              // ShopId: this.shop.id,
              day: item.day,
            })
          }
        }
      })

      return baseSchedule
    },
    queryUnit () {
      return this.$route.query.unit
    },
    queryDate () {
      return this.$route.query.date
    },
    queryFrom () {
      return this.$route.query.from
    },

  },

  watch: {
    sidebar () {
      // const api = this.$refs.calendar.getApi()
      // api.render()
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 300)
      console.log('resize')
    },

    allMergeSchedule: {
      handler (val) {
        this.allSchedule = []
        this.allMergeSchedule.forEach(async (item) => {
          const data = {
            day: item.day,
            appointmentUnitId: item.AppointmentUnitId,
            appointmentScheduleDayId: item.AppointmentScheduleDayId,
          }
          if (!item.AppointmentScheduleDayId) delete data.appointmentScheduleDayId

          this.allSchedule.push(data)
        })
      },
      deep: true,
    },

    mergeEditSchedule: {
      handler: function (val) {
        this.editCalendar.events = []
        this.editNewSchedule = []

        this.mergeEditSchedule.forEach(async (item) => {
          const shiftsClass = this.shopShiftsClassList.find(
            (shiftsClass) => shiftsClass.id === item.AppointmentScheduleDayId,
          )
          if (!shiftsClass) return
          const times = shiftsClass.AppointmentScheduleDayTimes
          const color = item.AppointmentScheduleDay.color

          this.editNewSchedule.push({
            day: item.day,
            appointmentUnitId: item.AppointmentUnit.id,
            appointmentScheduleDayId: item.AppointmentScheduleDayId,
          })

          times.forEach((time) => {
            const newEvent = this.createCalendarEvent({
              title: item.AppointmentUnit.name,
              day: item.day,
              timeStart: time.start,
              timeEnd: time.end,
              mode: 'edit',
              props: {
                day: item.day,
                appointmentUnitId: item.AppointmentUnit.id,
                appointmentScheduleDayId: item.AppointmentScheduleDayId,
              },
              color,
            })
            this.editCalendar.events.push(newEvent)
          })
        })
      },
      deep: true,
    },
  },

  async mounted () {
    if (this.queryDate) {
      this.editCalendar.initialDate = new Date(this.queryDate)
    } else this.editCalendar.initialDate = new Date()
    await this.getShopShiftsClass()
    await this.getShiftsSchedule()
    if (this.queryUnit) {
      await this.findServiceUnit(this.queryUnit)
      // this.unit = { id: this.queryUnit }
      await this.changeServiceUnit(this.unit)
    }
  },

  methods: {
    async findServiceUnit (id) {
      if (!id) return
      try {
        const res = await FindServiceUnit({
          shopId: this.shop,
          id,
        })

        this.unit = res
      } catch (error) {
        console.log(error)
      }
    },
    cancelEdit () {
      if (this.queryFrom) {
        this.$router.push({
          name: this.queryFrom,
        })
      } else {
        this.$router.push({
          name: 'ShiftsCalendarSetting',
        })
      }
    },
    // => 更新服務人員所有排班緩存
    updateUnitSchedule (unit) {
      const schedule = this.shopScheduleList.filter(
        (item) => item.AppointmentUnit.id === unit.id,
      )
      if (!this.unitSchedule[unit.id]) {
        this.$set(this.unitSchedule, unit.id, [])
      }
      this.unitSchedule[unit.id].push(...schedule)
      this.unitSchedule[unit.id] = uniqBy(this.unitSchedule[unit.id], 'id')
    },

    // => 更新當前編輯服務人員班表緩存
    async updateEditUnitSchedule (unitId, data) {
      if (!this.editUnitSchedule[unitId]) this.$set(this.editUnitSchedule, unitId, [])
      const exist = findIndex(this.editUnitSchedule[unitId], i => i.day === data.day)
      if (exist !== -1) {
        this.$set(this.editUnitSchedule[unitId], exist, data)
      } else this.editUnitSchedule[unitId].push(data)
    },

    // => 切換月曆(前後)
    async changeCalendar () {
      const prevDayStart = this.calendarRangeString.dayStart
      this.calendarRange = this.getCalendarDate(this.$refs.calendar.getApi())
      const currentDayStart = this.calendarRangeString.dayStart
      if (prevDayStart === currentDayStart) return
      await this.getShiftsSchedule()
      if (this.unit) { await this.changeServiceUnit(this.unit) }
    },

    // => 點擊月曆格子
    editDateClick: function (date) {
      date.jsEvent.preventDefault()
      localDebounce(date.dateStr, () => {
        if (!this.shiftClass) {
          this.$message.warning('請先選擇班別')
          return
        }
        const day = date.dateStr
        const serviceUnit = this.unit.id
        const unitName = this.unit.name
        const scheduleClass = this.shiftClass.id
        const color = this.shiftClass.color
        const times = this.shiftClass.AppointmentScheduleDayTimes

        // 當日已存在該班別則無視
        const isExist = this.editNewSchedule.find((item) => {
          if (day === item.day) {
            if (serviceUnit === item.appointmentUnitId) {
              if (scheduleClass === item.appointmentScheduleDayId) {
                return true
              }
            }
          }
        })

        if (isExist) return

        this.editCalendar.events = this.editCalendar.events.filter((event) => {
          const start = event.start.split(' ')[0]
          return start !== day
        })
        this.editNewSchedule = this.editNewSchedule.filter(
          (item) => day !== item.day,
        )

        // TODO 不可選上下月?

        // 增加暫存班表列表
        this.editNewSchedule.push({
          day,
          appointmentUnitId: serviceUnit,
          appointmentScheduleDayId: scheduleClass,
        })

        const dayEvents = []
        // 增加月曆事件
        times.forEach((item) => {
          const newEvent = this.createCalendarEvent({
            title: unitName,
            day,
            timeStart: item.start,
            timeEnd: item.end,
            mode: 'edit',
            props: {
              day,
              appointmentUnitId: serviceUnit,
              appointmentScheduleDayId: scheduleClass,
            },
            color,
          })

          this.editCalendar.events.push(newEvent)
          dayEvents.push(newEvent)
          this.updateEditUnitSchedule(serviceUnit, {
            day,
            mode: 'add',
            schedule: {
              day,
              appointmentUnitId: serviceUnit,
              appointmentScheduleDayId: scheduleClass,
            },
            dayEvents,
          })
        })
      })
    },
    // => 點擊月曆事件
    editEventClick: function (e) {
      e.jsEvent.preventDefault()
      localDebounce(e.event.extendedProps.day, () => {
        const date = e.event.extendedProps.day
        const scheduleClass = e.event.extendedProps.appointmentScheduleDayId

        const targetEvent = this.editNewSchedule.find((item) => {
          const itemDay = item.day
          const itemScheduleClass = item.appointmentScheduleDayId
          return date === itemDay && scheduleClass === itemScheduleClass
        })
        if (!targetEvent) return

        const events = this.editCalendar.events.filter((item) => {
          const itemDay = item.extendedProps.day
          const itemScheduleClass =
              item.extendedProps.appointmentScheduleDayId
          return date === itemDay && scheduleClass === itemScheduleClass
        })

        // 移除班別
        let index
        index = this.editNewSchedule.indexOf(targetEvent)
        const obj = this.editNewSchedule[index]
        delete obj.appointmentScheduleDayId
        // 移除event
        events.forEach((event) => {
          index = this.editCalendar.events.indexOf(event)
          this.editCalendar.events.splice(index, 1)
        })

        this.updateEditUnitSchedule(this.unit.id, {
          day: date,
          mode: 'remove',
          appointmentScheduleDayId: scheduleClass,
        })

        // if(targetEvent) {
        //   this.$confirm(`是否移除${date}的班別`, '提示', {
        //     confirmButtonText: '確定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {

        //     this.$message({
        //       type: 'success',
        //       message: '删除成功!'
        //     })
        //   }).catch(()=> {})
        // }
      })
    },

    // => 選擇服務人員 > 顯示班表
    async changeServiceUnit (v) {
      this.loading = true
      this.updateUnitSchedule(v)
      this.loading = false
    },

    async saveEditSchedule () {
      await this.updateShiftsSchedule(this.allSchedule)
      if (this.queryFrom) {
        this.$router.push({
          name: this.queryFrom,
        })
      } else {
        this.$router.push({
          name: 'ShiftsCalendarSetting',
        })
      }
      // await this.getShopShiftsClass()
      // await this.getShiftsSchedule()
      // await this.changeServiceUnit(this.unit)
    },
  },
}
</script>

<style scoped lang="postcss">

::v-deep .fc .fc-scroller-liquid-absolute {
  display: block;
  position:  unset !important;
  overflow: hidden !important;
  /* height: 100%; */
}

::v-deep .el-form--label-top .el-form-item__label{
  @apply p-0;
}

.edit-shift-calendar {
  @apply pb-[200px] mb-[200px] h-full flex flex-col;
}

.page-footer {

  box-shadow: 0px -4px 8px rgba(47, 32, 27, 0.04);
  padding: 15px 60px !important;
  /* background: white; */
  @apply fixed flex justify-end bottom-0 left-0 right-0 gap-[30px];
  @apply bg-white z-[5];
}

.controls-container {
  @apply flex gap-[24px] ;
}

@media screen and (max-width:1024px){
 .controls-container {
  @apply flex-wrap gap-0;
}
}
</style>
